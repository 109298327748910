import ProgressBar from '@badrap/bar-of-progress';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputLabel } from '@mui/material';
import { convertDate } from '@server/utils';
import { RangePicker } from 'components/common/RangePicker/RangePicker';
import { ReturnMessage } from 'components/common/ReturnMessage';
import update from 'immutability-helper';
import get from 'lodash.get';
import { useSession } from 'next-auth/client';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import {
  defaultProgressBarOptions,
  editableCustomType,
  editableOriginalType,
  employeeHistoryRegex,
  Roles,
  SALARY_TYPE_V1,
} from '../../config';
import { getDefaultTeam } from '../../config/fixBidCaclulatorDefaultTeam';
import useSocket from '../../hooks/useSocket';
import { projectReducer } from '../../reducers/projectReducer';
import { useOverheadStore } from '../../store/overhead/action';
import { useTeamStore } from '../../store/team/action';
import theme from '../../theme';
import { FixBidCalculatorProperties, Project } from '../../types/components/TeamMembers';
import { forcedSignOut, getLastHistoryValue, isNumeric, projectBillableTypes, round } from '../../utils';
import { ShowNumber, TeamMembers } from '../common';
import { Box } from '../common/Box';
import CalendarToolbar from '../common/CalendarToolbar/CalendarToolbar';
import { NumberControl } from '../common/NumberControl';
import { FinancialResults } from '../FinancialResults';
import { Parameter } from '../FinancialResults/FinancialResultsProperties';
import { Overhead } from '../Overhead';
import { useStyles } from './styles';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };

const defaultValues = {
  revenue: 200000,
  referralFeePercentage: 5,
  discountForClientPercentage: 5,
  targetProjectMarginPercentage: 60,
  contingency: 4,
  calculateTheSpentBudget: 0,
  spentDeliverySalaryCOGS: 0,
  spentOverhead: 0,
};

export const FixBidCalculator: FC<FixBidCalculatorProperties> = ({
  projects,
  employees: defaultEmployees,
  overhead: defaultOverhead,
  experiences: defaultExperiences,
  isStaging,
  syncStartDate,
}) => {
  const [session] = useSession();
  let fetchProjectTimer: number;
  const { enqueueSnackbar } = useSnackbar();
  const formReference = useRef(null);

  const classes = useStyles();
  const [employees, setEmployees] = useState(defaultEmployees);
  const [experiences, setExperiences] = useState(defaultExperiences);

  const snackbarOptions = {
    anchorOrigin,
    autoHideDuration: 30000,
    preventDuplicate: false,
    className: classes.snackbar,
  };

  const [newProjectName, setNewProjectName] = useState('');
  const [isOldProject, setIsOldProject] = useState(false);
  const [reportLink, setReportLink] = useState('');
  const [status, setStatus] = useState(0);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [values, setValues] = useState({
    revenue: defaultValues.revenue,
    targetProjectMarginPercentage: defaultValues.targetProjectMarginPercentage,
    referralFeePercentage: defaultValues.referralFeePercentage,
    discountForClientPercentage: defaultValues.discountForClientPercentage,
    contingency: defaultValues.contingency,
    calculateTheSpentBudget: defaultValues.calculateTheSpentBudget,
    spentDeliverySalaryCOGS: defaultValues.spentDeliverySalaryCOGS,
    spentOverhead: defaultValues.spentOverhead,
  });

  forcedSignOut(status);

  const defaultFrom = syncStartDate ? new Date(syncStartDate) : null;
  const minimumDate = defaultFrom && `${defaultFrom.toLocaleString('en-US').split(',')[0]}`;
  const defaultTo = new Date();
  const [range, setRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: defaultFrom,
    to: defaultTo,
  });

  const calculatorPrecision = isStaging ? 4 : 2;
  const userRole: string = session?.user?.roles ? session.user.roles[0] : '';
  const isDMorAdmin = [Roles.Admin, Roles.DepManager].includes(userRole as Roles);

  const fetchExperienceRates = async (): Promise<void> => {
    const result = await fetch(`/api/experienceRates`);

    forcedSignOut(result.status);
    const data = await result.json();

    setExperiences(data);
  };

  const defaultTeam = getDefaultTeam(isStaging, employees, isDMorAdmin, defaultExperiences);
  const { state: team, actions: teamActions } = useTeamStore(defaultTeam);
  const { state: overhead, actions: overheadActions } = useOverheadStore({
    type: editableOriginalType,
    value: defaultOverhead,
  });
  const fetchOverhead = async (): Promise<void> => {
    const month = new Date().toISOString().slice(0, 7);
    const result = await fetch(`/api/overheads/${month}`);

    forcedSignOut(result.status);
    const data = await result.json();

    return overheadActions.setOverhead({
      overheadType: editableOriginalType,
      value: get(data, 'overhead', 0),
    });
  };

  const [projectStore, dispatchProject] = useReducer(projectReducer, {
    project: projects[0],
    spentBudgetTimestamp: new Date(),
  });

  const { project, spentBudgetTimestamp } = projectStore;

  // @ts-ignore
  useEffect(() => {
    const progress = new ProgressBar(defaultProgressBarOptions);
    const abortController = new AbortController();
    const { signal } = abortController;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const fetchSpentBudget = async () => {
      try {
        if (!project.id) {
          setValues((currentValues) => ({
            ...currentValues,
            calculateTheSpentBudget: 0,
            spentDeliverySalaryCOGS: 0,
            spentOverhead: 0,
          }));

          return null;
        }
        progress.start();
        const from = range.from && !isNaN(range.from.valueOf()) && `from=${convertDate(range.from)}`;
        const to = range.to && !isNaN(range.to.valueOf()) && `to=${convertDate(range.to)}`;
        const query = [from, to].filter(Boolean).join('&');
        const result = await fetch(`/api/projects/${project.id}?${query}`, {
          signal,
        });

        forcedSignOut(result.status);
        const data = await result.json();

        progress.finish();

        setValues((currentValues) => ({
          ...currentValues,
          calculateTheSpentBudget: round(Number(data.spentBudget)),
          spentDeliverySalaryCOGS: round(Number(data.redmineSalary) + Number(data.redmineNonBillableSalary)),
          spentOverhead: round(Number(data.overhead)),
        }));
      } catch (error) {
        if (!signal.aborted) {
          console.error(error);
        }
      }
    };

    fetchSpentBudget();

    return () => {
      abortController.abort();
      progress.finish();
    };
  }, [project, range, spentBudgetTimestamp]);

  useSocket('realtime', async (message: Types.Controllers.Watchers.Messages) => {
    const { entity, action } = message;

    if (entity === 'projects') {
      const {
        project: { name, id: redmineId },
      } = message as Types.Controllers.Watchers.ProjectMessage;

      if (project.id === redmineId) {
        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
        enqueueSnackbar(`Project "${name}" has been updated`, snackbarOptions);
      }
    }

    if (entity === 'employees') {
      const {
        employee: { id: teamMemberId, name, history: historyObject },
        updateDescription: updatedFields,
        affectedProjects,
        isUSBased,
        isExperienceOrUSBasedChanged,
      } = message as Types.Controllers.Watchers.EmployeeMessage;
      const history = Object.entries(updatedFields)
        .filter(([key]) => key.match(employeeHistoryRegex))
        .map(([key, value]) => {
          const [, entityName, date] = key.match(employeeHistoryRegex) || [];

          return `The "${entityName}" parameter has been set to ${value} since ${date}.`;
        })
        .join(' and ');

      const salaryType = getLastHistoryValue(historyObject.salaryType, false);
      const rate = getLastHistoryValue(historyObject.rate, false);
      const rate2 = getLastHistoryValue(historyObject.rate2, false);
      const stake = getLastHistoryValue(historyObject.stake, false);
      const employmentType = getLastHistoryValue(historyObject.employmentType, false);

      const teamMemberRate =
        salaryType === SALARY_TYPE_V1 ? Number(rate) : Number(rate2) + Number(stake) / (160 * Number(employmentType));

      const employeeIndex = employees.findIndex(({ id }) => id === teamMemberId);

      if (employeeIndex >= 0) {
        setEmployees((_employees) =>
          update(_employees, {
            [employeeIndex]: (member) =>
              update(member, {
                rate: { $set: Number(teamMemberRate) },
              }),
          }),
        );
      }

      teamActions.setMemberRate({ teamMemberId, teamMemberRate });

      const hasExperience =
        isExperienceOrUSBasedChanged ||
        isUSBased ||
        (historyObject.experience && Object.keys(historyObject.experience).length > 0);

      if (hasExperience) {
        fetchExperienceRates();
      }

      dispatchProject({ type: 'FETCH_SPENT_BUDGET', affectedProjects });

      enqueueSnackbar(`Employee "${name}" has been updated.\n${history}`, snackbarOptions);
    }

    if (entity === 'overheads' && overhead.type === editableOriginalType) {
      const { month, value } = message as Types.Controllers.Watchers.OverheadMessage;
      const isSpentBudgetAffected = !range.to || new Date(`${month}-01`).getTime() < new Date(range.to).getTime();

      if (isSpentBudgetAffected) {
        if (action === 'delete') {
          enqueueSnackbar(`${month} overhead has been deleted.`, snackbarOptions);
          await fetchOverhead();
          dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
        }

        enqueueSnackbar(`Overhead has been  has been set to ${value} since ${month}.`, snackbarOptions);

        overheadActions.setOverhead({
          value: Number(value),
        });

        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
      }
    }

    if (entity === 'timeEntries') {
      const projectId = get(message, 'projectId');

      if (project.id === projectId) {
        if (fetchProjectTimer) {
          window.clearTimeout(fetchProjectTimer);
        }

        fetchProjectTimer = window.setTimeout(async () => {
          dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
          enqueueSnackbar(`The spent budget has been updated`, snackbarOptions);
        }, 2000);
      }
    }
  });

  const changeHandler = async ({ code, value }: { code: string; value: unknown }): Promise<void> => {
    try {
      setValues({ ...values, [code]: value || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const changeProjectHandler = async (projectData: Project): Promise<void> => {
    setNewProjectName(projectData.name);
    setIsOldProject(true);
    dispatchProject({
      type: 'CHANGE_PROJECT',
      project: projectData,
    });
  };

  const calc = useMemo(() => {
    values.contingency = values.contingency || 0;
    const referralFee = (values.revenue * values.referralFeePercentage) / 100;
    const discountForClient = (values.revenue * values.discountForClientPercentage) / 100;
    const targetProjectMargin = (values.revenue * values.targetProjectMarginPercentage) / 100;
    const budgetWithoutContingency =
      values.revenue - (values.revenue * values.referralFeePercentage) / 100 - discountForClient - targetProjectMargin;
    const contingency = (budgetWithoutContingency * values.contingency) / 100;
    const budgetWithContingency = budgetWithoutContingency - contingency; // [Budget without contingency - Contingency, $]
    const availableBudget = budgetWithContingency - values.calculateTheSpentBudget; // [Budget with contingency - Calculation of the Spent Budget, $]
    const teamData = team.map(({ employee, role, customRate, hours, ...rest }) => {
      const { billable = 0 } = hours || {};
      const salaryRate = isNumeric(`${customRate}`) ? customRate : experiences[role];
      const salary = employee
        ? Number(employee.rate || 0) * Number(billable)
        : Number(salaryRate || 0) * Number(billable);

      return {
        role,
        rate: experiences[role] || 0,
        customRate,
        hours: { billable },
        employee,
        salary,
        ...rest,
        rateType: undefined,
      };
    });

    const estimateHours = team.reduce((accumulator, { hours }) => {
      const { billable = 0 } = hours || {};

      return accumulator + billable;
    }, 0);
    const estimatedDeliverySalaryCOGS = teamData.reduce((accumulator, { salary }) => {
      return accumulator + salary;
    }, 0);

    let effectiveClientRate = 0;

    if (estimateHours !== 0) {
      effectiveClientRate = Number(values.revenue) / estimateHours;
    }
    const estimateOverhead = estimateHours * Number(overhead.value);
    const estimateBudget = estimatedDeliverySalaryCOGS + estimateOverhead;
    const estimateCostOfHour = estimateHours ? estimateBudget / estimateHours : 0;
    const projectMarginWithoutContingency =
      values.revenue - referralFee - discountForClient - estimatedDeliverySalaryCOGS;
    const projectMarginWithContingency =
      values.revenue -
      referralFee -
      discountForClient -
      contingency -
      values.calculateTheSpentBudget -
      estimatedDeliverySalaryCOGS;
    const companyAGMWithContingency = values.revenue - referralFee - discountForClient - contingency - estimateBudget;
    const companyAGMWithoutContingency =
      values.revenue - referralFee - discountForClient - values.calculateTheSpentBudget - estimateBudget;

    let projectMarginPercentWithoutContingency = 0;
    let companyAGMPercentWithContingency = 0;
    let companyAGMPercentWithoutContingency = 0;
    let projectMarginPercentWithContingency = 0;

    if (values.revenue !== 0) {
      projectMarginPercentWithoutContingency = (projectMarginWithoutContingency / values.revenue) * 100;
      projectMarginPercentWithContingency = (projectMarginWithContingency / values.revenue) * 100;
      companyAGMPercentWithContingency = (companyAGMWithContingency / values.revenue) * 100;
      companyAGMPercentWithoutContingency = (companyAGMWithoutContingency / values.revenue) * 100;
    }

    return {
      referralFee: round(referralFee),
      discountForClient: round(discountForClient),
      targetProjectMargin: round(targetProjectMargin),
      budgetWithoutContingency: round(budgetWithoutContingency),
      contingency: round(contingency),
      budgetWithContingency: round(budgetWithContingency),
      availableBudget: round(availableBudget),
      team: teamData,
      estimateHours: round(estimateHours),
      estimatedDeliverySalaryCOGS: round(estimatedDeliverySalaryCOGS),
      effectiveClientRate: round(effectiveClientRate),
      estimateOverhead: round(estimateOverhead),
      estimateBudget: round(estimateBudget),
      estimateCostOfHour: round(estimateCostOfHour),
      projectMarginWithoutContingency: round(projectMarginWithoutContingency),
      projectMarginPercentWithoutContingency: round(projectMarginPercentWithoutContingency),
      projectMarginWithContingency: round(projectMarginWithContingency),
      projectMarginPercentWithContingency: round(projectMarginPercentWithContingency),
      companyAGMWithContingency: round(companyAGMWithContingency),
      companyAGMPercentWithContingency: round(companyAGMPercentWithContingency),
      companyAGMWithoutContingency: round(companyAGMWithoutContingency),
      companyAGMPercentWithoutContingency: round(companyAGMPercentWithoutContingency),
    };
  }, [values, team, overhead.value, experiences]);

  const changeNewProjectName = (event: SyntheticEvent): void => {
    const target = event.target as HTMLTextAreaElement;

    setNewProjectName(target.value);
  };

  const generateReport = async (): Promise<number | undefined> => {
    // @ts-ignore
    const validated = formReference.current.reportValidity();

    if (validated) {
      const result = await fetch('/api/calculator/generateFixBidReport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newProjectName,
          isOldProject,
          values,
          calc,
          range,
          overhead,
        }),
      });

      const report = await result.json();

      setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
      setStatus(result.status);
      setIsSnackbarOpen(true);

      return result.status;
    }
  };

  const handleCloseSnackbar = (): void => setIsSnackbarOpen(false);

  const handleDateChange = async (key: string, date: Date | null): Promise<void> => {
    if (date instanceof Date && !isNaN(date.valueOf())) {
      await setRange({
        ...range,
        [key]: date,
      });
    }
  };

  const handleRangePickerSelect = async (dateFrom: Date, dateTo: Date): Promise<void> => {
    if (dateFrom instanceof Date && !isNaN(dateFrom.valueOf()) && dateTo instanceof Date && !isNaN(dateTo.valueOf())) {
      await setRange({
        ['from']: dateFrom,
        ['to']: dateTo,
      });
    }
  };

  const overheadChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    overheadActions.setOverhead({
      overheadType: editableCustomType,
      value: Number(event.target.value),
    });
  };

  const financialResults = {
    withoutContingency: [
      {
        title: 'Project Margin',
        percent: calc.projectMarginPercentWithoutContingency,
        value: calc.projectMarginWithoutContingency,
      },
      {
        title: 'Company AGM',
        percent: calc.companyAGMPercentWithoutContingency,
        value: calc.companyAGMWithoutContingency,
      },
    ],
    withContingency: [
      {
        title: 'Project Margin',
        percent: calc.projectMarginPercentWithContingency,
        value: calc.projectMarginWithContingency,
      },
      {
        title: 'Company AGM',
        percent: calc.companyAGMPercentWithContingency,
        value: calc.companyAGMWithContingency,
      },
    ],
  };

  const estimate: Parameter[] = [
    { title: "Effective Client's rate", value: calc.effectiveClientRate },
    { title: 'Budget without contingency', value: calc.budgetWithoutContingency },
    { title: 'Budget with contingency', value: calc.budgetWithContingency },
    { title: 'Spent Delivery Salary (COGS)', value: values.spentDeliverySalaryCOGS },
    { title: 'Spent Overhead', value: values.spentOverhead },
    { title: 'Available budget', value: calc.availableBudget },
    {
      title: 'Overhead/hour',
      component: (
        <Overhead
          value={overhead.value || 0}
          overhead={overhead}
          fetchOverhead={fetchOverhead}
          overheadChangeHandler={overheadChangeHandler}
        />
      ),
    },
    { title: 'Estimate Hours', value: calc.estimateHours },
    { title: 'Estimated Delivery Salary (COGS)', value: calc.estimatedDeliverySalaryCOGS },
    { title: 'Estimate Overhead', value: calc.estimateOverhead },
    { title: 'Estimate Budget', value: calc.estimateBudget },
    { title: 'Estimate Cost of hour', value: calc.estimateCostOfHour },
  ];

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" ref={formReference}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box title="Settings">
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projects">Project</InputLabel>
                    <Autocomplete
                      id="projects"
                      size="small"
                      openOnFocus
                      blurOnSelect
                      value={project}
                      options={projects}
                      getOptionLabel={(option) => option.name}
                      renderInput={(parameters) => <TextField {...parameters} variant="outlined" />}
                      renderOption={({ name, billableType }) => (
                        <>
                          {name}
                          {billableType && (
                            <sup className={classes.span}>&nbsp;{projectBillableTypes[`${billableType}`]}</sup>
                          )}
                        </>
                      )}
                      onChange={async (_event, value) => value && changeProjectHandler(value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  {project.id ? (
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container spacing={1}>
                          <Grid item xs={11} md={5}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="projects">From</InputLabel>
                              <KeyboardDatePicker
                                autoOk
                                clearable
                                disableFuture
                                inputVariant="outlined"
                                variant="dialog"
                                size="small"
                                value={range.from}
                                minDate={syncStartDate || undefined}
                                format="MM/dd/yyyy"
                                onChange={(date) => handleDateChange('from', date)}
                                ToolbarComponent={CalendarToolbar}
                                InputAdornmentProps={{
                                  classes: {
                                    root: classes.adornment,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={11} md={5}>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="projects">To</InputLabel>
                              <KeyboardDatePicker
                                clearable
                                autoOk
                                disableFuture
                                inputVariant="outlined"
                                variant="dialog"
                                size="small"
                                value={range.to}
                                format="MM/dd/yyyy"
                                onChange={(date) => handleDateChange('to', date)}
                                ToolbarComponent={CalendarToolbar}
                                InputAdornmentProps={{
                                  classes: {
                                    root: classes.adornment,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            <RangePicker selectRange={handleRangePickerSelect} />
                          </Grid>
                        </Grid>
                      </MuiPickersUtilsProvider>
                      {minimumDate && (
                        <p>
                          <a
                            href={'/tools/settings'}
                            style={{ textDecoration: 'none', color: `${theme.palette.primary.main}` }}
                          >
                            <strong> Minimal Date is: </strong>
                            {minimumDate}
                          </a>
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <InputLabel htmlFor="projects">New Project</InputLabel>
                      <TextField
                        required
                        value={newProjectName}
                        autoFocus
                        fullWidth
                        size="small"
                        variant="outlined"
                        onChange={changeNewProjectName}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="projects">S&F Revenue</InputLabel>
                  <NumberControl
                    id="revenue"
                    adornmentPosition="start"
                    adornment="$"
                    eventCode="revenue"
                    defaultValue={values.revenue}
                    changeHandler={changeHandler}
                    hasThousandSeparator
                  />
                </Grid>
                <Grid item xs={12} md={9}></Grid>

                <Grid item xs={6} md={3}>
                  <InputLabel htmlFor="referralFeePercentage">Referral Fee</InputLabel>
                  <NumberControl
                    id="referralFeePercentage"
                    adornmentPosition="end"
                    adornment="%"
                    eventCode="referralFeePercentage"
                    defaultValue={values.referralFeePercentage}
                    changeHandler={changeHandler}
                    hasThousandSeparator
                    noMarginBottom
                  />
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.referralFee} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputLabel htmlFor="discountForClientPercentage">Discount for Client</InputLabel>
                  <NumberControl
                    id="discountForClientPercentage"
                    adornmentPosition="end"
                    adornment="%"
                    eventCode="discountForClientPercentage"
                    defaultValue={values.discountForClientPercentage}
                    changeHandler={changeHandler}
                    hasThousandSeparator
                  />
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.discountForClient} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputLabel htmlFor="targetProjectMarginPercentage">Target Project Margin</InputLabel>
                  <NumberControl
                    id="targetProjectMarginPercentage"
                    adornmentPosition="end"
                    adornment="%"
                    eventCode="targetProjectMarginPercentage"
                    defaultValue={values.targetProjectMarginPercentage}
                    changeHandler={changeHandler}
                    hasThousandSeparator
                  />
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.targetProjectMargin} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <InputLabel htmlFor="contingency">Contingency</InputLabel>
                  <NumberControl
                    id="contingency"
                    adornmentPosition="end"
                    adornment="%"
                    eventCode="contingency"
                    defaultValue={values.contingency}
                    changeHandler={changeHandler}
                    hasThousandSeparator
                  />
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.contingency} prefix="$" withAnimation condensed />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box title="Team">
              <TeamMembers
                team={calc.team}
                employees={employees}
                onCloneTeamMember={teamActions.cloneTeamMember}
                onDeleteTeamMember={teamActions.deleteTeamMember}
                onChangeRole={teamActions.changeRole}
                onChangeEmployee={teamActions.changeEmployee}
                onChangeHours={teamActions.setHours}
                onChangeCustomRate={teamActions.setRoleCustomRate}
                onRemoveCustomRate={teamActions.removeRoleCustomRate}
                addTeamMember={teamActions.addTeamMember}
                totalHours={calc.estimateHours}
                totalSalary={calc.estimatedDeliverySalaryCOGS}
                experiences={experiences}
                precision={calculatorPrecision}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <FinancialResults title="Financial Results" {...financialResults} />
            <FinancialResults title="Estimate" root={estimate} />

            <Button color="primary" variant="contained" className={classes.reportButton} onClick={generateReport}>
              Export to Google sheet
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbarOpen}
        onClose={handleCloseSnackbar}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={status} />
      </Snackbar>
    </>
  );
};
// @ts-ignore
FixBidCalculator.whyDidYouRender = true;
